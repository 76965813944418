<template>
    <div class="col-12">
        <template v-if="notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
        </template>
        <div v-else>
            <form @submit.prevent="submit" novalidate class="mt-5">
                <div class="text-center">
                    <img height="100px" :src="getCryptoLogo(cryptoCurrencyCode)" style="background-color: transparent"/>
                </div>

                <div class="form-group floating-input mt-3 input-group">
                    <input type="number" class="form-control" id="cryptoAmount" v-model.trim="$v.amount.$model" :placeholder="$t('amount')" required>
                    <label for="cryptoAmount">{{$t('amount')}}</label>
                    <div class="input-group-append">
                        <span class="input-group-text">{{playerCurrency}}</span>
                    </div>
                </div>

                <div class="validation-error">
                    <label v-if="!$v.amount.required && $v.amount.$dirty">{{$t('please-enter-your', { field: $t('amount') })}}</label>
                    <label v-else-if="!$v.amount.numeric && $v.amount.$dirty">{{$t('not-a-number')}}</label>
                    <label v-else-if="!$v.amount.minValue && $v.amount.$dirty">{{$t('min-amount', { value: minWithdrawal }) }}</label>
                    <label v-else-if="!$v.amount.maxValue && $v.amount.$dirty">{{$t('max-amount', { value: maxWithdrawal }) }}</label>
                </div>

                <div class="form-group floating-input mt-3">
                    <input type="text" class="form-control" id="cryptoAddress" v-model.trim="$v.address.$model" :placeholder="$t('crypto-address')" required>
                    <label for="cryptoAddress">{{$t('crypto-address')}}</label>
                </div>

                <div class="validation-error">
                    <label v-if="!$v.address.required && $v.address.$dirty">{{$t('please-enter-your', { field: $t('crypto-address') })}}</label>
                    <label v-else-if="!$v.address.validateAddress && $v.address.$dirty">{{$t('please-enter-your', { field: $t('crypto-address-valid') })}}</label>
                </div>

                <button class="btn btn-success col-12 mt-3" :class="{'disabled':submitStatus === 'PENDING'}">
                    <div v-if="submitStatus !== 'PENDING'"><strong>{{$t('submit')}}</strong></div>
                    <div v-else class="d-flex align-items-center">
                        <strong>{{$t('loading')}}...</strong>
                        <div class="spinner-border spinner-border-sm ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </button>

                <div>
                    <label v-if="submitStatus === 'ERROR'" class="validation-error">{{cryptoWithdrawError}} {{$t('request-unsuccessful')}}</label>
                    <label v-if="submitStatus === 'OK'" class="validation-success">{{$t('request-successful')}}</label>
                </div><br/>

                <p class="text-center">{{$t('payout-note')}}</p>
            </form>
        </div>
    </div>
</template>

<script>
    import store from '../../../store/shared_state';
    import {getCryptoLogo} from '../../../helpers/helpers';
    import {withdraw} from '../../../services/backend.service';
    import {validationMixin} from 'vuelidate';
    import {required, numeric, minValue, maxValue} from 'vuelidate/lib/validators';
    import walletAddressValidator from '@swyftx/api-crypto-address-validator/dist/wallet-address-validator.min.js';
    // Used mainly as a fallback for BECH32 Wallet Addresses
    // Fallback will probably fail on USDTE coin
    import walletAddressValidatorFallback from 'trezor-address-validator/dist/wallet-address-validator.min.js';

    let cryptoCodesToValidate = ["BTC", "LTC", "BCH", "ETH", "USDTE", "TRX"];

    const validateAddress = (value,vm) => {
        if (value == null)
            return  false;

        if (cryptoCodesToValidate.includes(vm.cryptoCurrencyCode)) {
            let currencyCode = "";
            if (vm.cryptoCurrencyCode === "USDTE")
                currencyCode = "USDT";
            else
                currencyCode = vm.cryptoCurrencyCode;

            return walletAddressValidator.validate(vm.address, currencyCode) ? walletAddressValidator.validate(vm.address, currencyCode) : walletAddressValidatorFallback.validate(vm.address, currencyCode);
        }
        return true;
    }

    export default {
        name: 'CoinspaidWithdraw',
        data() {
            return {
                notEnoughData: null,
                playerCurrency: null,
                amount: null,
                minWithdrawal: null,
                maxWithdrawal: null,
                address: null,
                cryptoCurrencyCode: null,
                submitStatus: null,
                cryptoWithdrawError: null,
                customerId: null,
                processorName: null,
                ipAddress: null,
                payoutToken: null,
            }
        },
        components: {
            Error: () => import('../../others/Error'),
        },
        mixins: [validationMixin],
        validations() {
            return {
                amount: {
                    required,
                    numeric,
                    minValue: minValue(this.minWithdrawal),
                    maxValue: maxValue(this.maxWithdrawal),
                },
                address: {
                    required,
                    validateAddress,
                }
            }
        },
        computed: {
            tokenData() { return store.state.tokenData; },
        },
        mounted() {
            const tokenData = this.tokenData

            console.log('-----Coinspaid Data from Store-----')
            console.log(tokenData)

            if(tokenData && tokenData.customerId && tokenData.extra.cryptoCurrencyCode && tokenData.processorName && tokenData.ipAddress) {
                this.playerCurrency = tokenData.currencyCode;
                this.minWithdrawal = tokenData.extra.min;
                this.maxWithdrawal = tokenData.extra.max;
                this.cryptoCurrencyCode = tokenData.extra.cryptoCurrencyCode;
                this.customerId = tokenData.customerId;
                this.processorName = tokenData.processorName;
                this.ipAddress = tokenData.ipAddress;
                this.payoutToken = tokenData.payoutToken;
            } else {
                this.notEnoughData = true
            }
        },
        methods: {
            getCryptoLogo(cryptoCode){ return getCryptoLogo(cryptoCode); },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    // this.submitStatus = 'ERROR';
                } else {
                    this.submitStatus = 'PENDING';

                    const data = {
                        customerId:     this.customerId,
                        processorName:  this.processorName,
                        ipAddress:      this.ipAddress,
                        currency:       this.playerCurrency,
                        // Backend is working with cents so we need to pass cents hence * 100
                        amount:         this.amount * 100,
                        accountNumber:  this.address,
                        bankName:       this.cryptoCurrencyCode,
                        payoutToken:    this.payoutToken,
                        walletType:     "DEFAULT",
                    };

                    withdraw(data).then((res) => {
                        if(res.data.success){
                            this.submitStatus = 'OK';
                        } else {
                            this.cryptoWithdrawError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                            this.submitStatus = 'ERROR';
                        }
                    }).catch( e => {
                        this.submitStatus = 'ERROR';
                        console.log(`%c Error in CryptoWithdrawal.vue - method: submit - ${e}`, 'color:red');
                    });
                }
            }
        }
    }
</script>